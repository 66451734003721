import React, { Component } from 'react'
import { GridCol, GridRow, GridContainer } from '@ilc-technology/cefcom-ui-library'
import { connect } from 'react-redux'
import { selectProgramCode } from '../../redux/actions'
import { getAvailableGridCols } from '../../utils/columnChecker'
import SubTile from './subTile'
import { NotSureCard } from '@ilc-technology/cefcom-teaser-card'
import { SUBTILE_PROGRAM_SECTION_ID } from '../../redux/constants'
import { ISubTiles, IServiceLabel } from '../../../types'
import { getNestedVals, getLabel } from '@ilc-technology/cefcom-utils'
import { SIMPLIFIED_MARKETS } from '../../constants'

class SubTiles extends Component<ISubTiles, IServiceLabel> {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(subTile) {
    const { code, programCodes } = subTile
    const upperProgramCodes = programCodes.toUpperCase()
    this.props.selectProgramCode(code, upperProgramCodes)
  }

  render() {
    const {
      data,
      content: { rendering, slug, subTiles },
    } = this.props
    let counter = 0
    const serviceLabels = getNestedVals(data, ['page', 'serviceLabels'])
    const mc = getNestedVals(data, ['app', 'site', 'marketCode']).toLowerCase()

    // Checking which tile shows in which position - this will define if the title will show or not
    const secondComponent = getNestedVals(data, ['page', 'components', '[2]'])
    const firstComponentLanguage =
      getNestedVals(data, ['page', 'components', '[1]', 'slug']) === 'language-tile'
    const firstComponentLanguageSimplified =
      firstComponentLanguage && SIMPLIFIED_MARKETS.includes(mc)

    // check combined to show title / get title from component or service labels
    const showTitle =
      (!firstComponentLanguage &&
        slug === 'sub-tiles' &&
        secondComponent.rendering !== 'funneling-tiles') ||
      firstComponentLanguageSimplified
    const sectionTitle = secondComponent.sectionTitle
      ? secondComponent.sectionTitle
      : getLabel(serviceLabels, 'view-programs-by-type')

    return (
      <div id={SUBTILE_PROGRAM_SECTION_ID}>
        <section
          id={`omnidiv-funneling-smalltiles-${slug === 'sub-tiles' ? '1' : '2'}`}
          className={`
          ef-section rendering__${rendering} --smalltiles-${slug === 'sub-tiles' ? '1' : '2'} 
          ${firstComponentLanguageSimplified && '-padding-bottom'} ${
            firstComponentLanguage && '-padding-top-none'
          }`}
          data-rendering={rendering}
        >
          <GridContainer>
            {showTitle && <h2 className='ef-section-main-title'>{sectionTitle}</h2>}
            <GridRow classNames={[]}>
              {subTiles.map((subTile, i) => {
                counter += subTile.slug !== 'pg-all' && subTile.slug !== 'programguide' ? 1 : 0
                if (subTile.slug !== 'pg-all' && subTile.slug !== 'programguide') {
                  return (
                    <SubTile key={`${subTile.code}-${i}`} subTile={subTile} counter={counter} />
                  )
                } else {
                  return (
                    <GridCol
                      key={`${subTile.slug}-${i}`}
                      classNames={[
                        getAvailableGridCols(counter, true).s,
                        getAvailableGridCols(counter, true).m,
                        getAvailableGridCols(counter, true).l,
                      ]}
                    >
                      {subTile.anchorUrl.includes('#') ? (
                        <div
                          className='not-sure-card__category'
                          onClick={(e) => {
                            e.preventDefault()
                            this.handleClick(subTile)
                          }}
                        >
                          <NotSureCard
                            {...subTile}
                            siteUrl={`#${subTile.code}`}
                            siteBtnLabel={subTile.anchorText}
                            dataClickSubregion='program-by-category'
                            datainfo='ALL'
                          />
                        </div>
                      ) : (
                        <div className='not-sure-card__category'>
                          <NotSureCard
                            {...subTile}
                            siteUrl={subTile.anchorUrl}
                            siteBtnLabel={subTile.anchorText}
                            dataClickSubregion='program-by-category'
                            datainfo='ALL'
                          />
                        </div>
                      )}
                    </GridCol>
                  )
                }
              })}
            </GridRow>
          </GridContainer>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubTiles)
