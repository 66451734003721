import React from 'react'
import './styles.scss'

const PromoBanner = ({ promotionText, classNames, expiryDate }) => {
  const isExpired = expiryDate && new Date(expiryDate) < new Date()

  if (isExpired) {
    return null // Do not render anything if the promotion is expired
  }

  return (
    <div className={`stage-promo-banner ${classNames}`}>
      <div
        className='stage-promo-banner__text'
        dangerouslySetInnerHTML={{ __html: promotionText }}
      />
    </div>
  )
}

export default PromoBanner
