import React, { Component } from 'react'
import { WithBackgroundImagesWrapper } from '@ilc-technology/cefcom-ui-library'
import PromoBanner from '../PromoBanner'
interface IStage {
  title: string
  subTitle: string
  description: string
  labels: any[]
  anchorText: string
  anchorUrl: string
  stageRendering: string
  rendering: string
  isProductHeader: boolean
  isTransparent: boolean
  logoImages: any
  promotion: string[]
}

class Stage extends Component<IStage> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      data,
      data: { logoImages, images },
      labels,
      title,
      description,
      anchorText,
      anchorUrl,
      isProductHeader,
      isTransparent,
      stageRendering,
      children,
      marketCode,
      promotion,
    } = this.props
    return (
      <>
        <div
          className={` -gradient-top ${isProductHeader ? 'hero-product' : ''} ${
            !isTransparent ? '' : 'hero-transparent'
          } ${promotion ? '-promo-banner' : ''}`}
        >
          <WithBackgroundImagesWrapper
            gradientDesktop='linear-gradient(0deg,#191919,rgba(0,0,0,.1) 55%,rgba(25,25,25,0))'
            gradientMobile='linear-gradient(0deg,#191919,rgba(0,0,0,.1) 55%,rgba(25,25,25,0))'
            cssId='stage-content-background'
            imagesArray={images}
          >
            <div className='stage'>
              <div className='stage__content cefcom-container'>
                <div className='default'>
                  {logoImages && logoImages.length ? (
                    <img
                      src={logoImages}
                      className={`stage-logo-image ${
                        marketCode.toLowerCase() === 'fr' && 'fr__promotional-logo'
                      }`}
                    />
                  ) : null}

                  <div className='block-text'>
                    <h1 dangerouslySetInnerHTML={{ __html: title }} className='block-text__title' />
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </div>

                  {!anchorUrl || !anchorText ? null : (
                    <a href={anchorUrl} className={`btn ef-button btn--golden-gradient`}>
                      {anchorText}
                    </a>
                  )}
                  {children}
                </div>
              </div>
            </div>
          </WithBackgroundImagesWrapper>
          {promotion && promotion.promotionText && (
            <PromoBanner
              promotionText={promotion.promotionText}
              expiryDate={promotion.expiryDate}
              classNames={['-default']}
            />
          )}
        </div>
      </>
    )
  }
}

export default Stage
