import React, { Component } from 'react'
import { connect } from 'react-redux'
import USFunnelingCard from './USFunnelingCard'
import { NotSureCard } from '@ilc-technology/cefcom-teaser-card'
import { getNestedVals } from '@ilc-technology/cefcom-utils'
import { labelSelector } from '../../redux/selectors'
import { selectProgramCode } from '../../redux/actions'
import { SUBTILE_PROGRAM_SECTION_ID } from '../../redux/constants'
import { getAvailableGridCols } from '../../utils/columnChecker'
import { IUSTiles } from '../../../types'
import { GridContainer, GridRow, GridCol } from '@ilc-technology/cefcom-ui-library'

import './styles.scss'
import './mosaic.scss'

class USStudyComponent extends Component<IUSTiles> {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(subTile) {
    const { code, programCodes } = subTile
    const upperProgramCodes = programCodes.toUpperCase()
    this.props.selectProgramCode(code, upperProgramCodes)
  }

  render() {
    const {
      data,
      content,
      content: { slug, sectionTitle, rendering, subTiles },
    } = this.props
    let counter = 0
    const StageType = getNestedVals(data, ['page', 'components', '0'], null)

    return (
      <div className='' id={SUBTILE_PROGRAM_SECTION_ID}>
        <section
          id={`omnidiv-funneling-us-tiles`}
          className={`rendering__${rendering} section-wrapper ${
            rendering !== 'mosaic-funneling-tiles' ? '' : 'mosaic-wrapper'
          } ${
            StageType.rendering !== 'stage-text-only' ? '' : 'no-padding-top'
          } us-funneling-section`}
        >
          <GridContainer classNames={[`us-container`]}>
            {sectionTitle && <h2 className='ef-section-main-title'>{sectionTitle}</h2>}
            <GridRow classNames={[`funneling-tiles`]}>
              {subTiles.map((subTile, i) => {
                counter += subTile.images !== null ? 1 : 0

                // i.e. US | ID
                const mosaicFirstTallTile = subTile.rendering === 'us-first-sub-tile'
                const mosaicSecondTallTile = subTile.rendering === 'us-second-sub-tile'
                const mosaicCombTwoTiles = mosaicFirstTallTile || mosaicSecondTallTile
                const withButtons = slug === 'e1-id-sub-tiles-w-buttons'

                return (
                  <React.Fragment key={i}>
                    {subTile.slug !== 'pg-all' ? (
                      <GridCol
                        classNames={[
                          `-s-12 ${
                            mosaicFirstTallTile ? '-l-8 -mosaic-wider-tile ' : '-m-6 -l-4'
                          } mosaic-col`,
                        ]}
                      >
                        <USFunnelingCard
                          {...subTile}
                          rendering={rendering}
                          typeOfTile={subTile.rendering}
                          mosaicFirstTallTile
                          mosaicSecondTallTile
                        />
                      </GridCol>
                    ) : (
                      <GridCol
                        key={`${subTile.slug}-${i}`}
                        classNames={[
                          getAvailableGridCols(counter, true).s,
                          getAvailableGridCols(counter, true).m,
                          getAvailableGridCols(counter, true).l,
                        ]}
                      >
                        {subTile.anchorUrl === '#' ? (
                          <div
                            className='not-sure-card__category'
                            onClick={(e) => {
                              e.preventDefault()
                              this.handleClick(subTile)
                            }}
                          >
                            <NotSureCard
                              {...subTile}
                              siteBtnLabel={subTile.anchorText}
                              siteUrl={`#${subTile.code}`}
                              dataClickSubregion='program-by-category'
                              datainfo='ALL'
                            />
                          </div>
                        ) : (
                          <div className='not-sure-card__category'>
                            <NotSureCard
                              {...subTile}
                              siteUrl={subTile.anchorUrl}
                              siteBtnLabel={subTile.anchorText}
                              dataClickSubregion='program-by-category'
                              datainfo='ALL'
                            />
                          </div>
                        )}
                      </GridCol>
                    )}
                  </React.Fragment>
                )
              })}
            </GridRow>
          </GridContainer>
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  labels: labelSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(USStudyComponent)
