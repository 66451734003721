import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Footer } from '@ilc-technology/cefcom-react-footer'
import { ClickMap } from '@ilc-technology/cefcom-clickmap'
import { HeaderWrapper, TopBar, EOneMarketsPopup } from '@ilc-technology/cefcom-react-header'
import { IinitialProps } from '../../../types'
import { initPage } from '../../redux/actions'
import { CookieBanner } from '@ilc-technology/cefcom-react-cookie-banner'
import { MessageBanner } from '@ilc-technology/cefcom-message-banner'
import { getLabel, getNestedVals } from '@ilc-technology/cefcom-utils'
import { MetaTags } from '@ilc-technology/cefcom-metatags'
import Fonts from '@ilc-technology/cefcom-gud-core/build/fonts'
import { MarketSelectorBanner } from '@ilc-technology/cefcom-ms-banner'
import PreloadedFonts from '../PreloadedFonts'
import {
  isProgramsPopUpOpen,
  isPageStyleTransparentSelector,
  selectServiceLabels,
  popUpHasSubtile,
} from '../../redux/selectors'
import {
  SUBTILE_PROGRAM_SECTION_ID,
  AGE_PROGRAM_SECTION_ID,
  TOP_PAGE_ID,
} from '../../redux/constants'
import ProgramCardsPopup from '../../containers/ProgramCardsPopup'
import AvailableProgramsSeo from '../../containers/AvailableProgramsSeo'
import { IServiceLabel } from '../../../types'
interface IProps {
  data: IinitialProps
  initPage: (data: IinitialProps) => object
  labels: IServiceLabel[]
  isProgramsPopUpOpen: boolean
}
class Layout extends React.Component<IProps> {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
    }
  }
  abTestUpdateState() {
    window.cefcomSetStateFromExternal = (dataPath, newValue) => {
      // Split the property string into an array of strings
      const path = dataPath.split('.')
      // Start with the current state data
      let newData = this.state.data
      console.log('this.state.data>>>>', this.state.data)
      // Loop through the path array
      for (let i = 0; i < path.length; i++) {
        // Check if the current element is an array
        if (path[i].indexOf('[') > -1 && path[i].indexOf(']') > -1) {
          // split the array path and get the index of the element
          let arrPath = path[i].split('[')
          let arrIndex = Number(arrPath[1].split(']')[0])
          // Set the current object to the corresponding array element
          newData = newData[arrPath[0]][arrIndex]
        } else {
          // Set the current object to the corresponding property
          newData = newData[path[i]]
        }
      }
      // check if the current value is not undefined
      if (newData !== undefined) {
        // Use setState to update the state with the new value
        this.setState(
          // Create a new object with the updated value
          (prevState) => {
            const newData = { ...prevState.data }
            let currentObject = newData

            // Loop through the path array
            for (let i = 0; i < path.length; i++) {
              // Check if the current element is an array
              if (path[i].indexOf('[') > -1 && path[i].indexOf(']') > -1) {
                let arrPath = path[i].split('[')
                let arrIndex = Number(arrPath[1].split(']')[0])
                // Check if the current element is the last element of the path array
                if (i === path.length - 1) currentObject[arrPath[0]][arrIndex] = newValue
                else currentObject = currentObject[arrPath[0]][arrIndex]
              } else {
                // Check if the current element is the last element of the path array
                if (i === path.length - 1) currentObject[path[i]] = newValue
                else currentObject = currentObject[path[i]]
              }
            }
            return { data: newData }
          },
          // Call the props function after updating the state
          () => {
            this.props.initPage(this.state.data)
          },
        )
      } // If the current object is undefined, log an error message
      else {
        console.error('undefined object -> cefcomSetStateFromExternal', dataPath)
      }
    }
  }
  componentDidMount() {
    this.props.initPage(this.state.data)
    this.abTestUpdateState()
  }

  componentDidUpdate(prevProps) {
    this.handleVerticalScroll(prevProps)
  }

  handleVerticalScroll(prevProps) {
    try {
      if (prevProps.isProgramsPopUpOpen && !this.props.isProgramsPopUpOpen) {
        if (prevProps.popUpHasSubtile) {
          document.getElementById(SUBTILE_PROGRAM_SECTION_ID).scrollIntoView()
        } else {
          document.getElementById(AGE_PROGRAM_SECTION_ID).scrollIntoView()
        }
      }

      if (this.props.isProgramsPopUpOpen) {
        document.getElementById(TOP_PAGE_ID).scrollIntoView()
      }
    } catch (error) {}
  }
  render() {
    const { infoDate, isProgramsPopUpOpen, labels } = this.props
    const { data } = this.state
    const { app, header, page, footer, metatags, seolinks, programcards, lastModified } = data

    const mc = getNestedVals(app, ['site', 'marketCode'], null)
    const { gtmContainerId, isRtl } = app.site
    const { cookieBanner } = footer
    const { bannerServiceLabels, showBanner } = cookieBanner || {}
    const headerLabel = getNestedVals(header, ['header', 'topbar', 'labels'])
    const isTransparent = getNestedVals(page, ['isTransparent'], null)
    const lastUpdated = getNestedVals(app, ['site', 'lastUpdated'], 'no publish date found')
    const hrefLang = getNestedVals(metatags, ['hrefLang'], '')
    const inPageDb = getNestedVals(metatags, ['inPageDb'], '')

    // The "availableProgramCards" array is empty in Redux State until the page loads,
    // in order for the <AvailableProgramsSeo /> component to have data available
    // on the page source we need to get it from the pageContst (own props) instead of Redux state
    const availableProgramCards = getNestedVals(programcards, ['availableProgramCards'], [])

    const ageGroups = getNestedVals(programcards, ['ageGroupCategories'], [])
    const contentArea = getNestedVals(metatags, ['contentArea'])
    const contentAreaDetail = getNestedVals(metatags, ['contentAreaDetail'])
    const contentDatePage = getNestedVals(lastModified, ['page-storyblok'], null)
    const contentDateHeader = getNestedVals(lastModified, ['header'], null)
    const contentDateFooter = getNestedVals(lastModified, ['footer'], null)
    const contentDateMetatags = getNestedVals(lastModified, ['metatags'], null)
    const contentDateTogglelinks = getNestedVals(lastModified, ['togglelinks'], null)
    const contentDateChangecountry = getNestedVals(lastModified, ['changecountry'], null)
    const contentDateBreadcrumbs = getNestedVals(lastModified, ['breadcrumbs'], null)
    const contentDateProgramcards = getNestedVals(lastModified, ['programcards-storyblok'], null)
    let dataMasterEnglishPagename = '/funneling/'
    dataMasterEnglishPagename = dataMasterEnglishPagename.replaceAll('/', ':')
    const analytics = getNestedVals(
      data,
      ['page', 'analytics'],
      ` <div itemScope itemType='https://schema.org/WebSite'>
          <meta itemProp='url' content='${metatags.ogUrl}' />
          <meta itemProp='name' content='${metatags.metaTitle}'/>
        </div>`,
    )

    return (
      <div className='wrapper'>
        <div dangerouslySetInnerHTML={{ __html: analytics }} />

        <MetaTags metaData={{ meta: { seoMetaContentResultSet: metatags }, page: page }} />
        <ClickMap />
        <MarketSelectorBanner
          showBanner={getNestedVals(
            header,
            ['header', 'settings', 'showWrongCountryBanner'],
            false,
          )}
          marketCode={mc}
          debug={true}
        />

        <Helmet>
          <html
            data-lastUpdated={lastUpdated}
            data-htmlBuildDate={infoDate}
            className={`${
              isTransparent && !isProgramsPopUpOpen ? ' transparent ' : ''
            } mkt-${mc.toLowerCase()} ${isRtl ? 'rtl' : ''} ${
              !getNestedVals(page, ['page', 'displayHeaderShadow'], false)
                ? ' -no-header-shadow-page '
                : ''
            }`}
            data-inPageDb={inPageDb}
            lang={hrefLang}
            data-master-english-pagename={dataMasterEnglishPagename}
            data-content-area={contentArea}
            data-content-area-detail={contentAreaDetail}
            data-content-date-page={contentDatePage}
            data-content-date-header={contentDateHeader}
            data-content-date-footer={contentDateFooter}
            data-content-date-metatags={contentDateMetatags}
            data-content-date-togglelinks={contentDateTogglelinks}
            data-content-date-changecountry={contentDateChangecountry}
            data-content-date-breadcrumbs={contentDateBreadcrumbs}
            data-content-date-programcards={contentDateProgramcards}
            data-branch-version={process.env.BRANCH}
          />
          <script>
            {`(function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
              'gtm.start':
              new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
              '//www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', '${gtmContainerId}');`}
          </script>
        </Helmet>

        <PreloadedFonts mc={mc.toLowerCase()} />
        <Fonts mc={mc.toLowerCase()} />
        <MessageBanner labels={getLabel(headerLabel, 'covid-message')} showBanner={true} />
        {!header ? (
          <span />
        ) : (
          <HeaderWrapper data={header}>
            <TopBar data={header} />
            <EOneMarketsPopup data={header} />
          </HeaderWrapper>
        )}
        <div data-clickregion='page'>
          <main id='main-content' style={{ display: isProgramsPopUpOpen ? 'none' : 'block' }}>
            {React.cloneElement(this.props.children, { ...this.props, data: this.state.data })}
          </main>
          <ProgramCardsPopup
            ageGroups={ageGroups}
            data={data}
            labels={labels}
            isProgramsPopUpOpen={isProgramsPopUpOpen}
          />
          {/* {console.log('ageGroups?>>>>>>', ageGroups)} */}
          <AvailableProgramsSeo availableProgramCards={availableProgramCards} />
        </div>
        <Footer data={footer} seoLinks={seolinks} />
        <CookieBanner labels={bannerServiceLabels} showBanner={showBanner} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: ownProps.data,
  originalScrollY: state.originalScrollY,
  isTransparent: isPageStyleTransparentSelector(state),
  isProgramsPopUpOpen: isProgramsPopUpOpen(state),
  labels: selectServiceLabels(state),
  popUpHasSubtile: popUpHasSubtile(state),
})

const mapDispatchToProps = (dispatch) => ({
  initPage: (data) => dispatch(initPage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
